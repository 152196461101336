import messages from "../helpIconMessages";
import FeaturesStore from "../../FeaturesStore";
import * as FAC from "../featureAccessControl";
import type { SiteMap } from "../../navigator/RouteNode";
import PulseAiIcon from "../../../../../src/static/images/pulseAi/PulseAiLogo.svg?component";

export default {
  routes: [
    {
      path: "/",
      title: "Home",
      icon: "fas fa-home-lg-alt",
      children: [
        {
          path: "/snapshot",
          title: "Snapshot",
          icon: "",
        },
        {
          path: "/workflow2",
          title: "Tasks",
          icon: "",
        },
      ],
    },
    {
      title: "Pulse Ai",
      altText: "PulseAI",
      icon: PulseAiIcon,
      path: "/ai",
      exact: true,
      children: [
        {
          path: "/signals",
          title: "Signals",
          icon: "",
        },
        {
          path: "/riskMonitoring",
          title: "Risk Monitoring",
          icon: "",
        },
        // COFE-2271: /sentiment is an alternate path for /pulse, only displayed for users with Ask the AI permission.
        // This is necessary because the nav bar does not support multiple entries for the same URL with different
        // feature flags, and the name and location of Pulse/Sentiment should change when this flag changes.
        {
          title: "Sentiment",
          path: "/sentiment",
          icon: "",
          children: [
            {
              path: "/sentiment/summary",
              title: "Summary",
              hasPageTitleOverride: true,
            },
            {
              path: "/sentiment/explorer",
              title: "Explorer",
              hasPageTitleOverride: true,
            },
            {
              path: "/sentiment/yourTopics",
              title: "Your Topics",
              hasPageTitleOverride: true,
            },
          ],
        },
      ],
    },
    // Reputation
    {
      path: "/reputation",
      title: "Reputation",
      icon: "fas fa-comment-alt-lines",
      children: [
        {
          path: "/reviews/summary",
          title: "Summary",
          icon: "",
        },
        {
          path: "/reviews",
          title: "Reviews",
          icon: "",
        },
        // TODO- Review Builder Drill-ins
        {
          path: "/reviewBuilder",
          title: () => (!FeaturesStore.surveysEnabled ? "Review Builder" : "Surveys"),
          hasPageTitleOverride: true,
          icon: "",
        },
        {
          path: "/media",
          title: "Images",
          icon: "",
        },
        {
          path: "/competitors",
          title: "Competitors",
          icon: "",
        },
      ],
    },
    // Social
    {
      path: "/social",
      title: "Social",
      icon: "fas fa-user-friends",
      children: [
        {
          path: "/social/summary",
          title: "Summary",
          icon: "",
          children: [
            {
              path: "/social/summary/metrics",
              title: "Metrics",
              hasPageTitleOverride: true,
            },
            {
              path: "/social/summary/topPerformingPosts",
              title: "Top Performing Posts",
              hasPageTitleOverride: true,
            },
            {
              path: "/social/summary/providerBreakdown",
              title: "Provider Breakdown",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/social/feed",
          title: "Feed",
          icon: "",
          children: [
            {
              path: "/social/activity",
              title: "Posts and Comments",
              hasPageTitleOverride: true,
            },
            {
              path: "/social/hashtagMetrics",
              title: "Hashtag Metrics",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/social/publishing",
          title: "Publish",
          icon: "",
          hasPageTitleOverride: true,
        },
      ],
    },
    // Surveys
    {
      path: "/surveys",
      title: "Surveys",
      icon: "fas fa-clipboard-list-check",
      children: [
        {
          path: "/surveys/campaigns",
          title: "Campaigns",
          hasPageTitleOverride: true,
          icon: "",
        },
        {
          path: "/surveys/surveyForms",
          title: "Surveys",
          hasPageTitleOverride: true,
          icon: "",
        },
        {
          path: "/surveys/emailTemplates",
          title: "Emails",
          hasPageTitleOverride: true,
          icon: "",
        },
        {
          path: "/surveys/widgets",
          title: "Widgets",
          hasPageTitleOverride: true,
          icon: "",
        },
      ],
    },
    {
      path: "/listings",
      title: "Locations",
      icon: "fas fa-map-marker-alt",
      children: [
        {
          path: "/listings/accuracy/pages",
          title: "Listings Accuracy",
          icon: "",
          children: [
            {
              path: "/listings/accuracy",
              title: "Listings Accuracy",
            },
            {
              path: "/listings/accuracy/managed",
              title: "Managed Listings",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/accuracy/monitored",
              title: "Monitored Listings",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/listings/summary/pages",
          title: "Listings Summary",
          helpIconMessage: messages.listingSummary,
          icon: "",
          children: [
            {
              path: "/listings/summary",
              title: "Listings Summary",
            },
            {
              path: "/listings/summary/managed",
              title: "Managed Listings",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/summary/monitored",
              title: "Monitored Listings",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/listings/listingOptimization/pages",
          title: "Listings Optimization",
          helpIconMessage: messages.listingOptimization,
          icon: "",
          children: [
            {
              path: "/listings/listingOptimization",
              title: "Listings Optimization",
            },
            {
              path: "/listings/listingOptimization/managed",
              title: "Managed Listings",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/listingOptimization/monitored",
              title: "Monitored Listings",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/listings/missingListings/pages",
          title: "Missing Listings",
          icon: "",
          children: [
            {
              path: "/listings/missingListings",
              title: "Missing Listings",
            },
            {
              path: "/listings/missingListings/managed",
              title: "Managed Listings",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/missingListings/monitored",
              title: "Monitored Listings",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/listings/duplicateListings/pages",
          title: "Duplicate Listings",
          helpIconMessage: messages.duplicateListings,
          icon: "",
          children: [
            {
              path: "/listings/duplicateListings",
              title: "Duplicate Listings",
            },
            {
              path: "/listings/duplicateListings/managed",
              title: "Managed Listings",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/duplicateListings/monitored",
              title: "Monitored Listings",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/listings/publishingStatus",
          title: "Listing Publish Errors",
          icon: "",
        },
        {
          path: "/listings/localPagesReport",
          title: "Local Pages",
          icon: "",
        },
        {
          path: "/listings/locations",
          title: "Locations",
          icon: "",
        },
        {
          path: "/listings/pendingUpdates",
          title: "Scheduled Updates",
          icon: "",
        },
        {
          path: "/listings/searchToAdd",
          title: "Search To Add",
          icon: "",
        },
      ],
    },
    {
      title: () => (FAC.isSignalsEnabled() ? "Reports" : "Insights"),
      icon: "fas fa-analytics",
      path: "/reports",
      children: [
        // Dashboards Section
        // COFE-2271: /pulse moves and becomes /sentiment when Ask the AI Unleash flag is enabled. (See "Pulse AI")
        {
          title: "Pulse",
          path: "/pulse",
          sectionTag: "Dashboards",
          icon: "",
          children: [
            {
              path: "/pulse/summary",
              title: "Summary",
              hasPageTitleOverride: true,
            },
            {
              path: "/pulse/explorer",
              title: "Explorer",
              hasPageTitleOverride: true,
            },
            {
              path: "/pulse/yourTopics",
              title: "Your Topics",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/scores/summary",
          title: "LBV Score",
          helpIconMessage: messages.lbvScoreSummary,
          sectionTag: "Dashboards",
          icon: "",
        },
        {
          path: "/rankings",
          title: "SEO Rankings",
          sectionTag: "Dashboards",
          icon: "",
          children: [
            {
              path: "/rankings/avgRankings",
              helpIconMessage: messages.avgSEORankings,
              title: "SEO Rankings",
            },
            {
              path: "/rankings/page1Rankings",
              title: "Page 1 Rankings",
              hasPageTitleOverride: true,
              helpIconMessage: messages.page1SEORankings,
            },
          ],
        },
        {
          path: "/listings/searchAnalyticsReport",
          title: "Search Insights",
          sectionTag: "Dashboards",
          icon: "",
        },
        {
          path: "/locationComparison",
          title: "Location Comparison",
          sectionTag: "Dashboards",
          icon: "",
          children: [
            {
              path: "/reviews/storeComparison",
              title: "Reviews",
              hasPageTitleOverride: true,
            },
            {
              path: "/social/reports",
              title: "Social",
              hasPageTitleOverride: true,
            },
            // COFE-2271: /pulse moves and becomes /sentiment when Ask the AI Unleash flag is enabled. (See "Pulse AI")
            {
              path: "/pulse/storeComparison",
              title: "Pulse",
              hasPageTitleOverride: true,
            },
            {
              path: "/sentiment/storeComparison",
              title: "Sentiment",
              hasPageTitleOverride: true,
            },
            {
              path: "/listings/searchAnalyticsStoreComparison",
              title: "Search Insights",
              hasPageTitleOverride: true,
            },
            {
              path: "/scores/storeComparison/locations",
              title: "LBV Scores By Location",
              hasPageTitleOverride: true,
            },
            {
              path: "/scores/storeComparison/groups",
              title: "LBV Scores By Group",
              hasPageTitleOverride: true,
            },
          ],
        },
        {
          path: "/workflow2/reports",
          title: "Task Reports",
          sectionTag: "Dashboards",
          icon: "",
          children: [
            {
              path: "/workflow2/taskReport",
              title: "Tasks",
              hasPageTitleOverride: true,
            },
            {
              path: "/workflow2/userReport",
              title: "Users",
              hasPageTitleOverride: true,
            },
          ],
        },
        // Analytics Studio Section
        {
          path: "/emailAlerts/manage",
          title: "Email Reports",
          sectionTag: "Analytics Studio",
          icon: "",
        },
        {
          path: "/analytics/reports",
          title: "Custom Reports",
          hasPageTitleOverride: true,
          sectionTag: "Analytics Studio",
          icon: "",
        },
        {
          path: "/analytics/query-builder",
          title: "Query Builder",
          sectionTag: "Analytics Studio",
          icon: "",
        },
      ],
    },
  ],
} satisfies SiteMap;
