import * as mobx from "mobx";
import { UnleashClient } from "unleash-proxy-client";
import { ExtendedUser } from "../session/Authentication";
import SessionStoreInstance, { SessionStore } from "../session/SessionStore";
import config from "../../config";

const apiUrl = `${config.unleashApiUrl}`;
const apiKey = `${config.unleashApiKey}`;

// Unspecified flags default to disabled when api call fails

export class FeatureFlagStore {
  isReady = false;
  sessionStore: SessionStore;
  client: UnleashClient;
  _watched: Map<string, boolean> = new Map();

  constructor(userStore: SessionStore = SessionStoreInstance) {
    mobx.makeObservable(this, {
      isReady: mobx.observable,
      _setIsReady: mobx.action,
      _watched: mobx.observable,
      _noteEnabled: mobx.action,
    });

    // Start the background polling
    this.sessionStore = userStore;

    this.client = new UnleashClient({
      url: apiUrl,
      clientKey: apiKey,
      appName: "dashboard-ui",
      /**
       * Essentially disabling Unleash local storage cached flags to enable
       * the current flags default values when the Unleash service is unavailable
       */
      bootstrap: [
        {
          name: "",
          enabled: false,
          impressionData: false,
          variant: { name: "", enabled: false },
        },
      ],
    });

    this.client.on("update", () => {
      for (const featureName of this._watched.keys()) {
        this._noteEnabled(featureName, this.client.isEnabled(featureName));
      }
    });

    mobx.reaction(
      () => this.sessionStore.user,
      (user) => {
        if (user) {
          this.client.updateContext(toAttributes(user));
          if (!this.isReady) this.client.start().then(() => this._setIsReady(true));
        } else {
          this.client.stop();
          if (this.isReady) this._setIsReady(false);
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  isEnabled(featureName: string, defaultState = false): boolean {
    if (typeof defaultState === "undefined") defaultState = false;

    const enabled = this.client.isEnabled(featureName) || defaultState;

    if (!this._watched.has(featureName)) {
      this._noteEnabled(featureName, enabled);
    }
    return this._watched.get(featureName) || defaultState;
  }

  _noteEnabled(featureName: string, isEnabled: boolean) {
    this._watched.set(featureName, isEnabled);
  }

  _setIsReady = (isReady: boolean) => {
    this.isReady = isReady;
  };
}

type FeatureFlagContextProperties =
  | "accountId"
  | "accountIdPath"
  | "billingAccountName"
  | "billingAccountId"
  | "username"
  | "userType"
  | "customerType"
  | "impersonatedByInternalAdmin"
  | "type"
  | "environment";

interface ChatmeterFlagContext {
  userId: string;
  sessionId: string;
  properties: Record<FeatureFlagContextProperties, string>;
}

function toAttributes(user: ExtendedUser): ChatmeterFlagContext {
  return {
    userId: user.userId,
    sessionId: user.userId,
    properties: {
      type: "User",
      billingAccountName: user.billingAccountName,
      accountId: user.accountId,
      accountIdPath: user.accountIdPath || "NONE",
      billingAccountId: user.billingAccountId,
      username: user.username,
      userType: user.userType,
      customerType: user.reseller ? "reseller" : "direct",
      impersonatedByInternalAdmin: user.impersonatedByUser?.internalAdmin ? "true" : "false",
      environment: config.currentEnvironment,
    },
  };
}

const defaultStore = new FeatureFlagStore();
export default defaultStore;
