import BrandConfigStore from "../../services/config/BrandConfigStore";
import SessionStore from "../../services/session/SessionStore";
import * as mobx from "mobx";
import * as localStorage from "../../services/storage/localStorage";

export const features = [
  { path: "/snapshot", label: "Snapshot" },
  { path: "/reviews", label: "Reviews" },
  { path: "/social", label: "Social" },
  { path: "/pulse", label: "Sentiment" },
  { path: "/media", label: "Media" },
  { path: "/listings", label: "Listings" },
  { path: "/rankings", label: "Rankings" },
  { path: "/surveys", label: "Surveys" },
  { path: "/reviewBuilder", label: "Review Builder" },
  { path: "/workflow2", label: "Workflow" },
  { path: "/analytics", label: "Analytics" },
];

export class FeaturesStore {
  constructor() {
    mobx.makeObservable(this, {
      enabledRoutes: mobx.computed,
    });
  }
  get restrictions() {
    return BrandConfigStore.brandConfig ? BrandConfigStore.brandConfig.restrictions : {};
  }

  get permissions() {
    return (SessionStore.user && SessionStore.user.permissions) || [];
  }

  get snapshotEnabled() {
    return !this.restrictions.hideSnapshot && this.permissions.includes("ShowSnapshot");
  }

  get reviewsEnabled() {
    return !this.restrictions.hideReviews && this.permissions.includes("ShowReviews");
  }

  get socialEnabled() {
    return !this.restrictions.hideChatter2 && this.permissions.includes("ShowChatter");
  }

  get sentimentEnabled() {
    return !this.restrictions.hideSentiment && this.permissions.includes("ShowSentiment");
  }

  get mediaEnabled() {
    return !this.restrictions.hideMedia && this.permissions.includes("ShowMedia");
  }

  get listingsEnabled() {
    return !this.restrictions.hideListings && this.permissions.includes("ShowListings");
  }

  get rankingsEnabled() {
    return !this.restrictions.hideRankings && this.permissions.includes("ShowRankings");
  }

  get reviewBuilderEnabled() {
    return !this.restrictions.hideReviewGeneration && this.permissions.includes("ReviewBuilderAccess");
  }

  get workflowEnabled() {
    return (
      !this.restrictions.hideWorkflow && this.restrictions.hideWorkflow2 && this.permissions.includes("ShowWorkflow")
    );
  }

  get workflow2Enabled() {
    return (
      !this.restrictions.hideWorkflow2 && !!["ShowWorkflow", "TaskAdmin"].find((x) => this.permissions.includes(x))
    );
  }

  get analyticsEnabled() {
    return !this.restrictions.hideInsights && this.permissions.includes("ShowInsights");
  }

  get surveysEnabled() {
    return this.restrictions.surveys;
  }

  get surveysQrCodeEnabled() {
    return this.restrictions.surveysQrCodeGenerator;
  }

  enabledHash = {
    "/snapshot": () => this.snapshotEnabled,
    "/reviews": () => this.reviewsEnabled,
    "/social": () => this.socialEnabled,
    "/pulse": () => this.sentimentEnabled,
    "/media": () => this.mediaEnabled,
    "/listings": () => this.listingsEnabled,
    "/rankings": () => this.rankingsEnabled,
    "/reviewBuilder": () => this.reviewBuilderEnabled,
    "/analytics": () => this.analyticsEnabled,
    "/workflow2": () => this.workflow2Enabled,
  };

  isBeta({ label }) {
    return false;
  }

  routeEnabled(route) {
    let base = route.split("/").slice(0, 2).join("/");
    if (base in this.enabledHash) {
      return this.enabledHash[base]();
    } else {
      return true;
    }
  }

  get enabledRoutes() {
    return features.filter((x) => this.routeEnabled(x.path));
  }

  firstEnabledRoute() {
    return this.enabledRoutes.map((x) => x.path)[0];
  }

  newFlagPrefix = "visitedRoute";
  visitedTabEnoughToHideNewFlag(route) {
    return this.getVisitCount(route) >= 5;
  }

  onVisitedNewFlagTab(route) {
    localStorage.setItem(this.newFlagPrefix + ":" + route, JSON.stringify(this.getVisitCount(route) + 1));
  }

  getVisitCount(route) {
    const got = localStorage.getItem(this.newFlagPrefix + ":" + route);
    return got ? JSON.parse(got) : 0;
  }
}

export default new FeaturesStore();
